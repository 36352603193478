import axios from 'axios'
import auth from '~/utils/auth'

const getUniqueID = () => {
  const s4 = () => Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
  return s4() + s4() + '-' + s4();
}

const baseDomain = process.env.API_URL

var axiosDomain = axios.create({
  baseDomain
})

axiosDomain.interceptors.request.use(async (config) => {
  if (typeof window !== 'undefined') {
    await auth.refreshToken()
    .catch(e => {
      window.location.href = '/login'
    })
    config.headers.accesstoken = localStorage.idToken || getUniqueID()
  }
  return config
}, error => {
  window.location.href = '/login'
})

// Add a response interceptor
// https://github.com/axios/axios
// axiosDomain.interceptors.response.use(function (response) {
//   return response
// }, async function async (error) {
//   const originalRequest = error.config
//   // error = JSON.parse(JSON.stringify(error))
//   // if (error.response === 'undefined' || error.response.status === 401) {
//   //   await auth.refreshToken()
//   //     .catch(e => {
//   //       return Promise.reject(e)
//   //     })
//   //   const retryOriginalRequest = new Promise((resolve) => {
//   //     originalRequest.headers.accesstoken = localStorage.idToken
//   //     resolve(axios(originalRequest))
//   //   })
//   //   return retryOriginalRequest
//   // }
//   await auth.refreshToken()
//     .catch(e => {
//       return Promise.reject(e)
//     })
//   const retryOriginalRequest = new Promise((resolve) => {
//     originalRequest.headers.accesstoken = localStorage.idToken
//     resolve(axios(originalRequest))
//   })
//   return retryOriginalRequest
// })

export default {
  async getH2h(localteam, visitorteam) {
    const url = baseDomain + `/api/h2h/${localteam}/${visitorteam}`
    const config = {
      headers: {
        "accesstoken": localStorage.idToken || getUniqueID()
      }
    }
    return await axiosDomain.get(url, config)
  },

  async getStandings(seasonId) {
    const url = baseDomain + `/v2/standings/${seasonId}`
    const config = {
      ...(process.client ? { // localStorage is not accessible on the server. Throws an error on league page
        headers: {
          "accesstoken": localStorage?.idToken || getUniqueID()
        }
      } : {})
    }
    return await axiosDomain.get(url, config)
  },

  async getTeamsStats(seasonId) {
    const url = baseDomain + `/teams/${seasonId}/stats`
    return await axios.get(url)
  },

  async getSquads(season, localteam_season_id, visitorteam_season_id, localteam, visitorteam) {
    const url = baseDomain + `/api/squads/season/${season}/teams/${localteam}/${visitorteam}?localteam_season_id=${localteam_season_id}&visitorteam_season_id=${visitorteam_season_id}`
    const config = {
      headers: {
        "accesstoken": localStorage.idToken || getUniqueID()
      }
    }
    return await axiosDomain.get(url, config)
  },

  async getFixtures(date, is_live, timezone, lastGames, page, sort, cancelToken, filters={}, limit=null, basic_fields=null, filterType) {
    let url = baseDomain + `/api/fixtures/${date}/?is_live=${is_live}&last_games=${lastGames}&filter_type=${filterType}&timezone=${timezone}&page=${page}&sort=${sort}`
    if (limit) {
      url += `&limit=${limit}`
    }
    if (basic_fields) {
      url += `&basic_fields=${basic_fields}`
    }
    const body = {
      'filters': filters
    }
    const config = {
      headers: {
        'Content-Type': 'application/json',
        "accesstoken": localStorage.idToken || getUniqueID()
      },
      cancelToken
    }
    return await axiosDomain.post(url, body, config)
  },

  async getFixturesV2 (date, is_live, timezone, lastGames, page, sort, cancelToken, filters={}, limit=null, filterType) {
    let url = baseDomain + `/api/v2/fixtures/${date}/?is_live=${is_live}&last_games=${lastGames}&filter_type=${filterType}&timezone=${timezone}&page=${page}&sort=${sort}`
    if (limit) {
      url += `&limit=${limit}`
    }
    const body = {
      'filters': filters
    }
    const config = {
      headers: {
        'Content-Type': 'application/json',
        "accesstoken": localStorage.idToken || getUniqueID()
      },
      cancelToken
    }
    return await axiosDomain.post(url, body, config)
  },

  async getFixtureMinuteStats (fixtureId) {
    const url = baseDomain + `/api/v2/fixtures/${fixtureId}/minute-stats`
    const config = {
      headers: {
        'Content-Type': 'application/json',
        "accesstoken": localStorage.idToken || getUniqueID()
      },
    }
    return await axiosDomain.get(url, null, config)
  },

  async getFixtureOddsV2 (fixtureId, minute) {
    const url = baseDomain + `/api/v2/fixtures/${fixtureId}/odds?minute=${minute}`
    const config = {
      headers: {
        'Content-Type': 'application/json',
        "accesstoken": localStorage.idToken || getUniqueID()
      },
    }
    return await axiosDomain.get(url, null, config)
  },

  async getFixturesForBacktesting(league, lastGames, filters, outcome) {
    let url = baseDomain + `/api/backtesting/fixtures/${league}/?last_games=${lastGames}`
    const body = {
      filters,
      outcome
    }
    const config = {
      headers: {
        'Content-Type': 'application/json',
        "accesstoken": localStorage.idToken || getUniqueID()
      }
    }
    return await axiosDomain.post(url, body, config)
  },

  async saveBacktestingResults (body) {
    let url = baseDomain + `/api/backtesting/results`
    const config = {
      headers: {
        'Content-Type': 'application/json',
        "accesstoken": localStorage.idToken || getUniqueID()
      }
    }
    return await axiosDomain.post(url, body, config)
  },

  async getBacktestingByFilter (filterId, userDesiredOutcome, lastGames) {
    let url = baseDomain + `/api/backtesting/results/${filterId}?user_desired_outcome=${userDesiredOutcome}&last_games=${lastGames}`
    const config = {
      headers: {
        'Content-Type': 'application/json',
        "accesstoken": localStorage.idToken || getUniqueID()
      }
    }
    return await axiosDomain.get(url, config)
  },

  async backtestByFilters (data) {
    const url = `${baseDomain}/api/v2/backtesting`
    const config = {
      headers: {
        'Content-Type': 'application/json',
        "accesstoken": localStorage.idToken || getUniqueID()
      }
    }

    return await axiosDomain.post(url, data, config)
  },

  async getBacktestingResults (page) {
    let url = baseDomain + `/backtesting/results/?page=${page}`
    const config = {
      headers: {
        'Content-Type': 'application/json',
        "accesstoken": getUniqueID()
      }
    }
    return await axiosDomain.get(url, config)
  },

  async getSidebarFixture(id, date, timezone, lastGames, cancelToken) {
    const url = baseDomain + `/api/fixture/${id}/${date}?timezone=${timezone}&last_games=${lastGames}`
    const config = {
      headers: {
        "accesstoken": localStorage.idToken || getUniqueID()
      },
      cancelToken
    }
    return await axiosDomain.get(url, config)
  },

  async getSidebarSquad(id) {
    const url =  baseDomain + `/api/v2/squads/${id}`
    const config = {
      headers: {
        "accesstoken": localStorage.idToken || getUniqueID()
      }
    }
    return await axiosDomain.get(url, config)
  },

  async getFixtureOdds (id) {
    const url = baseDomain + `/api/odds/${id}`
    const config = {
      headers: {
        "accesstoken": localStorage.idToken || getUniqueID()
      }
    }
    return await axiosDomain.get(url, config)
  },

  async getQuickStats(query) {
    let url = baseDomain + `/quick-stats/${query.date}?last_games=${query.last_games}&filter_type=${query.filter_type}&hide_cup=${query.hide_cup}`
    return await axiosDomain.get(url)
  },
  
  async cancelSubscription (subscription_id) {
    const url = baseDomain + `/subscription/cancel/${subscription_id}`
    return await axiosDomain.post(url)
  },

  async getPortalSession (customer_id) {
    const url = baseDomain + `/api/customers-session/${customer_id}`
    const config = {
      headers: {
        "accesstoken": localStorage.idToken || getUniqueID()
      }
    }
    const res =  await axiosDomain.post(url, {}, config)
    return res
  },

  async getCheckoutSession (customer_id, price_id, coupon, customerId, currency, mode) {
    const url = baseDomain + `/api/checkout/${customer_id}/${price_id}/${coupon}?customerId=${customerId}&currency=${currency || 'eur'}&mode=${mode || 'subscription'}`
    const config = {
      headers: {
        "accesstoken": localStorage.idToken || getUniqueID()
      }
    }
    const res =  await axiosDomain.post(url, {}, config)
    return res
  },

  async getLinkStats (link) {
    const url = baseDomain + `/api/link-stats/${link}`
    const config = {
      headers: {
        "accesstoken": localStorage.idToken || getUniqueID()
      }
    }
    const res =  await axiosDomain.get(url, config)
    return res
  },

  async checkUser (id) {
    const url = baseDomain + `/api/users/check/${id}`
    const config = {
      headers: {
        "accesstoken": localStorage.idToken || getUniqueID()
      }
    }
    const res =  await axiosDomain.get(url, config)
    return res
  },

  async checkUserEmail (email, onesignalId) {
    const url = baseDomain + `/api/users/check`
    const config = {
      headers: {
        "accesstoken": localStorage.idToken || getUniqueID()
      }
    }
    const data = {
      email,
      onesignalId
    }
    const res =  await axiosDomain.post(url, data, config)
    return res
  },

  async getLeague (id) {
    const url = baseDomain + `/v2/leagues/${id}`
    const config = {
      ...(process.client ? { // localStorage is not accessible on the server
        headers: {
          "accesstoken": localStorage?.idToken || getUniqueID()
        }
      } : {})
    }
    const res = await axiosDomain.get(url, config)
    return res
  },

  async getTeamsBySeasonId (id) {
    const url = baseDomain + `/teams/season/${id}`
    const res = await axios.get(url)
    return res
  },

  async getTeam (id) {
    const url = baseDomain + `/teams/${id}`
    const res = await axios.get(url)
    return res
  },

  async getLeagues() {
    const url = baseDomain + `/leagues/xg`
    const res = await axios.get(url)
    return res
  },

  async getPlayer (id) {
    const url = baseDomain + `/v2/players/${id}`
    const res = await axios.get(url)
    return res
  },

  // stats endpoints
  async getBttsStats (where) {
    const url = baseDomain + `/stats/btts?where=${where}`
    const res = await axios.get(url)
    return res
  },
  async getXgStats (where) {
    const url = baseDomain + `/stats/xg?where=${where}`
    const res = await axios.get(url)
    return res
  },
  async getO15GoalsStats (where) {
    const url = baseDomain + `/stats/o15goals?where=${where}`
    const res = await axios.get(url)
    return res
  },
  async getO25GoalsStats (where) {
    const url = baseDomain + `/stats/o25goals?where=${where}`
    const res = await axios.get(url)
    return res
  },
  async getO05FHGoalsStats (where) {
    const url = baseDomain + `/stats/o05fhgoals?where=${where}`
    const res = await axios.get(url)
    return res
  },
  async getO95CornersStats (where) {
    const url = baseDomain + `/stats/o95corners?where=${where}`
    const res = await axios.get(url)
    return res
  },
  async getO35CardsStats (where) {
    const url = baseDomain + `/stats/o35cards?where=${where}`
    const res = await axios.get(url)
    return res
  }
}
