export default function ({ app, route }) {
  if (app.$isApp) {
    let title = ''
    if (route.name === 'register') {
      title = 'Create account'
    } else if (route.fullPath === '/' || route.query.date || (route.query.fixture && route.name === 'index')) {
      title = 'Fixtures'
    } else if (route.name === 'filters-explore') {
      title = 'Explore Filters'
    } else if (route.query.live === 'true') {
      title = 'Live'
    } else if (route.query.mygames === 'true') {
      title = 'My games'
    } else if (route.name === 'account-feeds') {
      title = 'Feeds'
    } else if (route.name === 'quick-stats') {
      title = 'Quick Stats'
    } else if (route.name === 'tracking') {
      title = 'Alerts Tracking'
    } else if (route.name === 'backtesting' || route.name === 'backtesting-v1') {
      title = 'Backtesting'
    } else if (route.name === 'more') {
      title = 'More'
    } else if (route.fullPath === '/') {
      title = 'Fixtures'
    } else if (route.name === 'stats-btts') {
      title = 'BTTS Stats'
    } else if (route.name === 'stats-cards') {
      title = 'Cards Stats'
    } else if (route.name === 'stats-corners') {
      title = 'Corners Stats'
    } else if (route.name === 'stats-over05-fh-goals') {
      title = 'Over 0.5 FHG Stats'
    } else if (route.name === 'stats-over15-goals') {
      title = 'Over 1.5 Goals Stats'
    } else if (route.name === 'stats-over25-goals') {
      title = 'Over 2.5 Goals Stats'
    } else if (route.name === 'stats-xg') {
      title = 'xG Stats'
    }
    if (title) {
      setTimeout(() => {
        window.location.href = 'gonative://navigationTitles/setCurrent?title=' + encodeURIComponent(title)
      }, 300)
    }
  }
}
