import api from '~/utils/api'
import orderBy from 'lodash.orderby'

export const state = () => ({
  league: {
    name: '',
    country: {
      data: {
        name: ''
      }
    },
    stages: [],
    rounds: [],
    fixtures: []
  },
  teams: [],
  standings: [],
  teamsStats: [],
})

export const mutations = {
  INITIATE_LEAGUE (state) {
    state.league = {
      name: '',
      country: {
        data: {
          name: ''
        }
      },
      stages: [],
      rounds: [],
      fixtures: []
    }
  },
  SET_LEAGUE(state, league) {
    state.league = league
  },
  SET_TEAMS(state, teams) {
    state.teams = teams 
  },
  SET_STANDINGS(state, standings) {
    state.standings = standings 
  },
  SET_TEAMS_STATS(state, teamsStats) {
    state.teamsStats = teamsStats 
  },
}

export const actions = {
  async GET_LEAGUE ({ commit, state }, payload) {
    if (payload !== state.league.id) {
      commit('INITIATE_LEAGUE')
      const res = await api.getLeague(payload)
      res.data.stages = orderBy(res.data.stages, 'sort_order')
      res.data.fixtures = orderBy(res.data.fixtures, 'starting_at')
      commit('SET_LEAGUE', res.data)
    }
  },
  async GET_TEAMS ({ commit, state }, payload) {
    let res = await api.getTeamsBySeasonId(state.league.season_id)

    res.data = orderBy(res.data, [( team ) => { return team?.stats?.l5?.xg?.total || ''}], 'desc')
    commit('SET_TEAMS', res.data)
  },
  async GET_STANDINGS_AND_TEAMS_STATS ({ commit, state }, payload) {
    try {
      const res = await Promise.all([api.getStandings(state.league.season_id), api.getTeamsStats(state.league.season_id)])

      commit('SET_STANDINGS', orderBy(res[0].data.standings, 'sort_order'))
      commit('SET_TEAMS_STATS', orderBy(res[1].data.teams, 'name'))
    } catch (e) {
      commit('SET_STANDINGS', [])
      commit('SET_TEAMS_STATS', [])
    }
  }
}
